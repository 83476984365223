import styles from "./ProgressCard.module.scss";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addIdSelectBlock, addIdSelectCours, addselectBlock } from "../../store/action/courses.slice";
function ProgressCard(props) {
  const navigete = useNavigate();
  const store = useSelector((state) => state.isTestSlice);

  const dispatch = useDispatch();

  // console.log("isTestSlice", store)
  const goVideo = () => {
    console.log("Yf;fk")
    console.log("props?.item", props?.item)
    // dispatch(addselectBlock({ select: props?.item?.index }));
    dispatch(addselectBlock({ select: 1 }));
    dispatch(addIdSelectBlock({id: props?.item?.id}))
    navigete("/CoursVideo");
  };

  const goTest = () => {
    navigete("/TestCours");
    dispatch(addselectBlock({ select: props?.item?.index }));
  };

  return (
    <div className={styles.ProgressCard}>
      <div className={styles.left}>
        <div className={styles.left_left}>
          <img src={props.item.img || "../img/carts.png"} alt="L" />
          <div className={styles.box}>
            <span>Блок {props.item.index || 1}</span>
            <p>{props.item.name}</p>
            <button className={styles.btnPk} onClick={() => goVideo()}>
              К видео
            </button>
          </div>
        </div>

        <div className={styles.progressBar}>
          <div className={styles.progressBar_bg}>
            <div
              style={{ width: `${props.item.progress || 0}` }}
              className={styles.progressBar_inner}
            ></div>
          </div>
          <p>{props.item.progress || 0}%</p>
        </div>
        <button className={styles.btnPhone} onClick={() => goVideo()}>К видео</button>
        <div className={styles.rigthPfone}>
          <p>{props.item.status || 0} <span>{props.item.statusProgress}%</span></p>
        </div>
      </div>

      <div className={styles.rigth} onClick={() => goTest()}>
        <p>{props.item.status}</p>
        <span>{props.item.statusProgress || 0}%</span>
      </div>
    </div>
  );
}

export default ProgressCard;
