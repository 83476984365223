import { useSelector } from "react-redux";
import styles from "./RezTest.module.scss";
import { useDispatch } from "react-redux";
import { resetcompleteTest, setcompleteTest } from "../../store/test/test.slice";
import {ReactComponent as ArrowRight} from "./../../images/arrowRight.svg";
import { useNavigate } from "react-router-dom";
import { nextSelectBlock } from "../../store/action/courses.slice";
import CoursHeaderTest from "../../components/CoursHeaderTest/CoursHeaderTest";
import Footer from "../../components/Footer/Footer";

function RezTest() {
  const store = useSelector((state) => state.coursesSlice.selectBlock);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goVizible = () => {
    dispatch(nextSelectBlock())
    dispatch(setcompleteTest(false))
    navigate("/CoursVideo")
  }
  return (
    <div className={styles.RezTest}>
      <CoursHeaderTest img="./img/LogoTest.svg" title={store}  imgBack={true}/>
        <div className={styles.RezTestInner}>
            <div>
            <p className={styles.title}>Твой результат:</p>
                <div className={styles.CircleBlock}>
                    <div className={styles.Circle}>
                        <p>85%</p>
                    </div>
                </div>
               
                <div className={styles.ButtonBlock}>
                    <button onClick={() => dispatch(setcompleteTest(false))} >Перепройти тест</button>
                    <button onClick={() => goVizible()}>Продолжить просмотр<span><ArrowRight/></span></button>
                </div>
            </div>  
        </div>
        <Footer/>
    </div>
  );
}

export default RezTest;
