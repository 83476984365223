export const faqData = [
  {
    index: "01",
    title: "Как можно повысить тариф?",
    text: "Возможность повысить тариф есть только у тарифа BASIC! Повышение курса даёт возможность получить доступ к блоку по заработку на картах Таро. Повысить курс можно перейдя в личный кабинет.",
  },
  {
    index: "02",
    title: "О чем курс?",
    text: "Курс построен на изучении карт Таро в теме отношений. На курсе подробно рассказывают о значении всех 78 карт и их применении в данной сфере.",
  },
  {
    index: "03",
    title: "Для кого предназначен этот курс?",
    text: "Курс подходит как для новичков, так и для тех, кто уже имеет опыт в работе с Таро. Мы предлагаем материалы, которые помогут каждому углубить свои знания.",
  },
  {
    index: "04",
    title: "Какова продолжительность курса?",
    text: "Курс состоит из 8 модулей и 25 серий для тарифа BASIC и 31 серия для тарифов STANDART и PRO. Вы можете изучать материалы в своем темпе.",
  },
  {
    index: "05",
    title: "Какие материалы включены в курс?",
    text: "В курс входят видеоуроки, тесты, индивидуальный блокнот таролога, домашние задания и доступ к закрытому сообществу для обсуждения и обмена опытом.",
  },
  {
    index: "06",
    title: "Нужно ли иметь свою колоду Таро?",
    text: "Да, наличие колоды Таро будет полезным для практики. Курс будет построен на классической колоде Таро Уэйта. Какую выбрать вы можете просмотреть в демо-уроке.",
  },
  {
    index: "07",
    title: "Как я могу получить доступ к курсу после покупки?",
    text: "После завершения покупки вы получите возможность выбора просмотра курса на сайте или в телеграмм боте. Все материалы будут доступны на нашей платформе.",
  },
  {
    index: "08",
    title: "Есть ли сертификат по окончании курса?",
    text: "Да, по завершении курса, и при выполнении всех тестов, Вы получите сертификат о его прохождении.",
  },
  {
    index: "09",
    title:
      "Как я могу связаться с вами для получения дополнительной информации?",
    text: "Вы можете связаться с нашей службой поддержки через форму обратной связи на сайте или по электронной почте nataro.company@mail.ru.",
  },
];
