import { useEffect, useRef, useState } from "react";
import styles from "./VideoPlayer.module.scss";

function VideoPlayer(props) {
  const server = process.env.REACT_APP_API_URL;
  const [permission, setPermission] = useState("720");
  const [permissionShow, setPermissionShow] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef(null);
  const hoverTimerRef = useRef(null); // Создаем ссылку для таймера

  useEffect(() => {
    let elem = null;
    if (videoRef) {
      elem = videoRef?.current;
    }
    console.log("videoRef", elem);
  }, [videoRef, props.activVideo]);

  const liClick = (value) => {
    setPermission(value);
    setPermissionShow(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    // Сбрасываем таймер при наведении
    clearTimeout(hoverTimerRef.current);
  };

  const handleMouseMove = () => {
    // Сбрасываем таймер при движении мыши
    setIsHovered(true);
    clearTimeout(hoverTimerRef.current);
    hoverTimerRef.current = setTimeout(() => {
      setIsHovered(false);
      setPermissionShow(false);
    }, 2500); // Установить таймер на 1 секунду
  };

  const handleMouseLeave = () => {
    // Очищаем таймер при уходе мыши
    clearTimeout(hoverTimerRef.current);
    setIsHovered(false);
  };

  useEffect(() => {
    // При размонтировании компонента очищаем таймер
    return () => {
      clearTimeout(hoverTimerRef.current);
    };
  }, []);

  return (
    <div className={styles.VideoPlayer}>
      <div className={styles.videoBlock}>
        <video
          onTouchStart={handleMouseMove}
          ref={videoRef}
          controls
          controlsList="nodownload"
          key={props.activVideo?.url720}
          src={`${server}/uploads/${props.activVideo?.[`url${permission}`]}`}
          poster={`${server}/uploads/${props.activVideo?.preview}`}
          onMouseEnter={handleMouseEnter} // Обработчик наведения
          onMouseMove={handleMouseMove} // Обработчик движения мыши
          onMouseLeave={handleMouseLeave} // Обработчик ухода мыши
        ></video>
        <div
          className={
            isHovered
              ? `${styles.permission} ${styles.permissionShow}`
              : styles.permission
          }
          //   style={{
          //     visibility: isHovered ? "visible" : "hidden",
          //     transition: "all 0.5s ease",
          //   }}
        >
          {!permissionShow && (
            <p
              onClick={() => setPermissionShow(!permissionShow)}
              onMouseEnter={handleMouseEnter} // Обработчик наведения
              onMouseMove={handleMouseMove} // Обработчик движения мыши
              onMouseLeave={handleMouseLeave} // Обработчик ухода мыши
            >
              {permission}
            </p>
          )}
          {permissionShow && (
            <ul
              onMouseEnter={handleMouseEnter} // Обработчик наведения
              onMouseMove={handleMouseMove} // Обработчик движения мыши
              onMouseLeave={handleMouseLeave} // Обработчик ухода мыши
            >
              <li onClick={() => liClick("480")}>480px</li>
              <li onClick={() => liClick("720")}>720px</li>
              <li onClick={() => liClick("1080")}>1080px</li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;
