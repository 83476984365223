import { createSlice } from "@reduxjs/toolkit";

const coursesSlice = createSlice({
  name: "courses",
  initialState: {
    selectBlock: 0,
    selectCourse: 0,
    idSelectBlock: null,
    numberSeleccttBlock: 0,
    idSelectCours: null,
    idSelectTest: null,
  },

  reducers: {
    //! добавить выбранный блок
    addselectBlock(state, action) {
      const { select, id } = action.payload;
      state.idSelectBlock = id;
      state.selectBlock = select;
    },

    addSelectTest(state, action) {
      const { id } = action.payload;
      state.idSelectTest = id;
    },

    addIdSelectCours(state, action) {
      const { id } = action.payload;
      state.idSelectCours = id;
    },

    addIdSelectBlock(state, action) {
      const { id } = action.payload;
      state.idSelectBlock = id;
    },

    nextSelectBlock(state) {
      state.selectBlock += 1;
    },

    removeSelectBlock(state) {
      state.selectBlock = "";
    },

    setSelectCourse(state, action) {
      state.selectCourse = action.payload.id;
    },
  },
});

// export const { addselectBlock, removeSelectBlock, nextSelectBlock } = isSelectBlockSlice.actions;
export const {
  setSelectCourse,
  addselectBlock,
  removeSelectBlock,
  nextSelectBlock,
  addIdSelectBlock,
  addIdSelectCours,
  addSelectTest
} = coursesSlice.actions;

export default coursesSlice.reducer;
