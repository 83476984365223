import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./CoursHeader.module.scss";

function CoursHeader(props) {
  const navigate = useNavigate();

  const handleBackClick = () => {
    const currentURL = window.location.href;
    console.log("Текущий URL:", currentURL);
    const url = currentURL.split("/");
    if(url[url.length - 1] === "Cours"){
      navigate("/");
    }else{
      navigate("/Cours");
    }
  };

  return (
    <div className={styles.CoursHeader}>
      <header>
        <div className={styles.headerLogo}>
            <div className={styles.headerLogoInner}>

        
            <div className={styles.arrowBack} onClick={handleBackClick}>
              <img src="./img/back.svg" alt="Назад"  />
            </div>
            <div>
            <img className={styles.headerLogoImg} style={{pointerEvents: "none"}} src={props.img} alt="Логотип" />
            </div>
          </div>
        </div>
        <div className={styles.line}></div>
      </header>
    </div>
  );
}

export default CoursHeader;
