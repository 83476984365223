import { useSelector } from "react-redux";
import styles from "./TestModule.module.scss";
import { useDispatch } from "react-redux";
import {
  addSelected,
  nextQuestion,
  resetQuestion,
  setcompleteTest,
} from "../../store/test/test.slice";
import { useEffect } from "react";
import { ReactComponent as ArrowRight } from "./../../images/arrowRight.svg";
function TestModule() {
  const store = useSelector((state) => state.isTestSlice);
  console.log("isTestSlice", store);
  const server = process.env.REACT_APP_API_URL;

  const dispatch = useDispatch();
  console.log("store.testData?.questions", store.testData?.questions);
  console.log("store.selectedQestion", store.selectedQestion);
  const nextQuestClick = () => {
    console.log("store.selectId", store.selectId);
    if (store.selected?.find((el) => el.idQuestion === store.selectId)) {
      if (store.selectedQestion != store.testData.questions?.length) {
        dispatch(nextQuestion());
      } else {
        dispatch(setcompleteTest(true));
      }
    }
  };

  return (
    <div className={styles.TestModule}>
      <ul className={styles.points}>
        {store?.testData?.questions?.map((_, index) => (
          <li
            key={index}
            className={
              store.selectedQestion === index + 1 ||
              index < store.selectedQestion
                ? styles.active
                : styles.Notactive
            }
          ></li>
        ))}
      </ul>
      <div className={styles.nameQuestion}>
        <div className={styles.numberQuestion}>
          <p>{store.selectedQestion}</p>
        </div>
        <div className={styles.titleQuestion}>
          <p>{store.testData?.questions[store.selectedQestion - 1]?.title}</p>
        </div>
      </div>
      <div className={styles.Question}>
        <div className={styles.QuestionInner}>
        { store.testData?.questions[store.selectedQestion - 1].file && 
          <div className={styles.QuestionImg}>
            <img
              src={`${server}/uploads/${
                store.testData?.questions[store.selectedQestion - 1]?.file
              }`}
            />
          </div>
        }
          <div className={styles.QuestionAnswers}>
            {store.testData?.questions[store.selectedQestion - 1]?.answers.map(
              (item, index) => (
                <div key={index}>
                  <button
                    onClick={() =>
                      dispatch(addSelected({ selectId: item?.id }))
                    }
                    className={
                      store.selected.find((el) => el.idAnsver === item?.id)
                        ? styles.QuestionAnswersBtnActiv
                        : styles.QuestionAnswersBtn
                    }
                  >
                    <p>{item.text}</p>
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div className={styles.buttonNextQuestion}>
        <button onClick={nextQuestClick}>
          {store.selectedQestion === store.testData.questions.length
            ? "Завершить тест"
            : "Следующий вопрос"}{" "}
          <span>
            <ArrowRight />
          </span>
        </button>
      </div>
    </div>
  );
}

export default TestModule;
