import { Link, useNavigate } from "react-router-dom";
import styles from "./TestRez.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { apiTest } from "../../store/test/test.slice";
import { apiGetTest } from "../../api/ApiRequest";

function TestRez() {
  const store = useSelector((state) => state);

    const TestRez ={
        rez: ""
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
const OpenTest = () => {
  navigate("/TestCours")
  console.log(store.isTestSlice)
  console.log(store.coursesSlice)
  apiGetTest(store.coursesSlice.idSelectTest).then((res) => {
    if(res?.status === 200) {
      console.log("test", res?.data);
      dispatch(apiTest({dataTest: res?.data})) 
    }
  })
}


  return (
    <div className={styles.TestRez}>
      <div className={styles.TestRezInner}>    
        {TestRez.rez === "" ? (
          <div className={styles.TestRezNot}>
            <div className={styles.TestRezInfo}>
              <p>Пройди тест</p>
              <p>Проверь насколько хорошо ты усвоил материал</p>
              {/* <Link to="/TestCours"> */}
                <img onClick={OpenTest} src="./img/back.svg" />
              {/* </Link> */}
            </div>
          </div>
        ) : (
          <div className={styles.TestRezGood}>
            <div className={styles.TestRezInfo}>
              <p>Тест пройден</p>
              <p>Твой результат:</p>
              <p>{TestRez.rez}</p>
            </div>
          </div>
        )}
        <div className={styles.GoNextBlock}>
          <div className={styles.GoNextBlockFlex}>
            <div className={styles.GoNextBlockInner}>
              <p>Перейти к следующему блоку</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestRez;
